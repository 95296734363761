<template>
  <div class="scriptas" id="select-scripta">
    <Select2 @select="select($event)" v-model="selectedItem" :settings="selectConfig" ref="select2" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'ScriptaSelect',
    props: {
      'exclude': {default: () => []},
      'languageCode': {default: () => null},
      'selected': {default: () => []}
    },
    components: {
      Select2
    },
    data(){
      return {
        selectedItem: this.selected ? this.selected : null,
        // selectedObj: this.selected
      }
    },
    mounted() {
      if (this.selected){
        // this.selected.forEach(scripta => {
          // let scriptLanguage = this.selected.languageCode ? ` (${this.selected.languageCode})` : ''
          this.$refs.select2.select2.append(
            `<option selected value="${this.selected}">${this.selected}</option>`
          ).trigger('change')
        // })
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a scripta",
          allowClear: true,
          width: '100%',
          multiple: false,
          dropdownParent: "#select-scripta",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/scriptas/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term,
                languageCode: this.languageCode
              }
            },
            processResults: (data) => {
              return {
                results: data.map(scripta => {
                  let text = scripta.languageCode ? `${scripta.code} (${scripta.languageCode})` : scripta.code
                  return {
                    id: scripta.code,
                    text: text,
                    scripta: scripta
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        // this.selectedObj = this.selectedObj.filter(scripta => this.selectedItem.includes(scripta.code))
        // if (event.selected){
        //   this.selectedObj.push(event.scripta)
        // }
        this.$emit('select', event.scripta)
      }
    }
  }
</script>

