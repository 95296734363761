import { render, staticRenderFns } from "./LocationNewEdit.vue?vue&type=template&id=da869bf6&scoped=true"
import script from "./LocationNewEdit.vue?vue&type=script&lang=js"
export * from "./LocationNewEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da869bf6",
  null
  
)

export default component.exports