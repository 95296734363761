<template>
  <div>
    <b-form-group
      id="input-town-group"
      label="Town:"
      label-for="input-town"
    >
      <b-form-input
        id="input-town"
        v-model="model.town"
        placeholder="Town"
        :disabled="unspecState"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-department-group"
      label="Department:"
      label-for="input-department"
    >
      <b-form-input
        id="input-department"
        v-model="model.department"
        placeholder="Department"
        :disabled="unspecState"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-region-group"
      label="Region:"
      label-for="input-region"
    >
      <b-form-input
        id="input-region"
        v-model="model.region"
        placeholder="Region"
        :disabled="unspecState"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-country-group"
      label="Country:"
      label-for="input-country"
    >
      <b-form-input
        id="input-country"
        v-model="model.country"
        placeholder="Country"
        :disabled="unspecState"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-unspecified-group"
      label="Unspecified:"
      label-for="input-unspecified"
    >
      <b-form-input
        id="input-unspecified"
        v-model="model.unspecified"
        placeholder="Unspecified"
        :disabled="regularState"
      ></b-form-input>
    </b-form-group>

    <hr>

    <b-form-group
      id="input-latitude-group"
      label="Latitude:"
      label-for="input-latitude"
    >
      <b-form-input
        id="input-latitude"
        v-model="model.latitude"
        placeholder="Latitude"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-longitude-group"
      label="Longitude:"
      label-for="input-longitude"
    >
      <b-form-input
        id="input-longitude"
        v-model="model.longitude"
        placeholder="Longitude"
      ></b-form-input>
    </b-form-group>

    <hr>

    <b-form-group
      id="input-dees-macro-group"
      label="Dees Macro:"
      label-for="input-dees-macro"
    >
      <b-form-input
        id="input-dees-macro"
        v-model="model.dees_macro"
        placeholder="Dees Macro"
      ></b-form-input>
    </b-form-group>

    <hr>

    <b-form-group
      id="input-scripta-group"
      label="Primary Scripta:"
      label-for="input-scripta"
    >
      <ScriptaSelectSingle
        :selected="model.scripta ? model.scripta : null"
        @select="updateScripta"
      />

    </b-form-group>

    <b-form-group
      id="input-comment-group"
      label="Comment:"
      label-for="input-comment"
    >
      <b-form-textarea
        id="input-comment"
        placeholder="Comment"
        v-model="model.comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import HelpStore from '@/store/helpstore.js'
  import ScriptaSelectSingle from '@/components/Main/ScriptaSelectSingle.vue'


  export default {
    name: 'LocationNewEdit',
    props: ['location'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.location ? this.location : {
          id: "",
          town: "",
          department: "",
          region: "",
          country: "",
          latitude: "",
          longitude: "",
          dees_macro: "",
          unspecified: "",
          comment: "",
          scripta_obj: {}
        })
      }
    },
    components: {
      ScriptaSelectSingle
    },
    mounted() {
      HelpStore.item = this.model
    },
    computed: {
        regularState() {return (this.model.town.length > 0 ||
                                this.model.department.length > 0 ||
                                this.model.region.length > 0 ||
                                this.model.country.length > 0) ? true : false},
        unspecState() {return this.model.unspecified.length > 0 ? true : false}
    },
    methods: {
      updateScripta(scripta) {
        this.model.scripta_obj = scripta
      },
    },
    updated(){
      HelpStore.item = this.model
    },
  }
</script>
