<template>
  <div>
    <PageTitle title="Locations" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-button v-if="config.editable" variant="primary" size="sm" :disabled="!$store.getters.checkRole('legam.main.location.add')" class="float-right mb-4" v-b-modal.modal-new-location>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new location
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-location" title="New location" ok-title="Save" @ok="createLocation" size="lg">
            <LocationNewEdit />
          </b-modal>
          <LegamDataTables
            v-if="config.editable"
            v-bind="$data"
            @selectChanged="selectChanged"
            @click="tableAction"
          />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-edit-location"
      title="Edit Location"
      ok-title="Save"
      @ok="saveLocation"
      size="lg"
    >
      <LocationNewEdit
        :key="locationModelKey"
        :location="locationModel"
      />
    </b-modal>

    <div class="merge-float" v-if="selected.length">
      <b-badge href="#" variant="info" class="mr-1" v-for="location in selected" :key="location.id" @click="removeSelected(location)">
        <span v-html="location.display"></span>
        <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
      </b-badge>
      <span class="btn-legam-xxs btn-legam-xs btn-legam-blue" v-if="selected.length > 1" v-b-modal.modal-merge>
        <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
        Merge locations
      </span>
      <span class="btn-legam-xxs btn-legam-xs" @click="selected = []">
        <b-icon icon="x" aria-hidden="true"></b-icon>
      </span>
      <b-modal id="modal-merge" title="Merge locations" ok-title="Merge" @ok="merge" size="md">
        <b-badge href="#" variant="info" class="mr-1" v-for="location in selected" :key="'modal' + location.id" @click="removeSelected(location)">
          <span v-html="location.display"></span>
          <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
        </b-badge>
        <b-form-group
          id="main-entity-grp"
          label="Merge to"
          label-for="main-entity"
          class="mt-3"
          description="This location will keep all it's data (other locations will move their data to this location and then disappear)"
        >
          <b-form-select
            v-model="mainEntityId"
            :options="selected.map(location => {
              return {
                'text': location.display_raw,
                'value': location.id
              }
            })"
          ></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }
  .merge-float {
    position: fixed;
    bottom: 15px;
    left: 20px;
    background-color: #bebebe9a;
    padding: 3px 8px 5px;
    border-radius: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  // import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import tdColAction from "@/components/tdColAction";
  import tdColSafeHTML from "@/components/tdColSafeHTML"
  import LocationNewEdit from "@/components/Main/LocationNewEdit"
  import HelpStore from '@/store/helpstore.js'
  import tdColUUID from "@/components/tdColUUID"
  import config from '@/config.js'

  export default {
    name: 'LocationsList',
    data(){
      return {
        columns: [
          { title: 'Id', field: 'id', sortable: true, searchable: true, tdComp: tdColUUID },
          { title: this.$t('Town'), field: 'town', sortable: true, searchable: true },
          { title: this.$t('Department'), field: 'department', sortable: true, searchable: true },
          { title: this.$t('Region'), field: 'region', sortable: true, searchable: true },
          { title: this.$t('Country'), field: 'country', sortable: true, searchable: true },
          { title: this.$t('Unspecified'), field: 'unspecified', sortable: true, searchable: true },
          { title: 'Scripta', field: 'scripta', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Geo', field: 'geo', sortable: true },
          { title: 'Dees Macro', field: 'dees_macro', sortable: true, searchable: true },
          { title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction', tdClass: 'no-select',
            transformData: function (row) {
              return config.editable ? [{
                icon: 'pencil',
                text: ' ',
                dataId: JSON.stringify({
                  action: 'edit',
                  location: {
                    id: row.id ? row.id : "",
                    town: row.town ? row.town : "",
                    department: row.department ? row.department : "",
                    region: row.region ? row.region : "",
                    country: row.country ? row.country : "",
                    latitude: row.latitude ? row.latitude : "",
                    longitude: row.longitude ? row.longitude : "",
                    dees_macro: row.dees_macro ? row.dees_macro : "",
                    scripta: row.scripta ? row.scripta : "",
                    scripta_obj: row.scripta_obj ? row.scripta_obj : {},
                    unspecified: row.unspecified ? row.unspecified : "",
                    comment: row.comment ? row.comment : ""
                  }
                })
              },
              {
                icon: 'trash',
                text: ' ',
                dataId: JSON.stringify({
                  action: 'delete',
                  id: row.id
                })
              }] : []
            }
          }
        ],
        columnsPI: [
          { title: this.$t('Town'), field: 'town', sortable: true, searchable: true },
          { title: this.$t('Department'), field: 'department', sortable: true, searchable: true },
          { title: this.$t('Region'), field: 'region', sortable: true, searchable: true },
          { title: this.$t('Country'), field: 'country', sortable: true, searchable: true },
          { title: this.$t('Unspecified'), field: 'unspecified', sortable: true, searchable: true },
          { title: 'Scripta', field: 'scripta', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          { title: 'Geo', field: 'geo', sortable: true }
        ],
        url: '/locations/datatables',
        key: 1,
        selected: [],
        mainEntityId: null,
        searchableByColumn: true,
        locationModel: null,
        locationModelKey: 0,
        config: config
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings
      }),
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      LocationNewEdit
    },
    methods: {
      createLocation(){
        this.$store.dispatch('createLocation', HelpStore.item).then(() => this.key++)
      },
      saveLocation(){
        this.$store.dispatch('saveLocation', HelpStore.item).then(() => this.key++)
      },
      selectChanged(items){
        this.selected = items
        if (items.length){
          this.mainEntityId = items[0].id
        }
      },
      removeSelected(item) {
        this.selected = this.selected.filter(_item => item.id != _item.id)
      },
      tableAction(action) {
        action = JSON.parse(action)
        if (action.action == 'delete') {
          this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
            if (value){
              this.$store.dispatch('deleteLocation', {
                id: action.id
              }).then(() => {
              this.$store.dispatch('addNotification', {
                type: 'success',
                text: `Location deleted`
                })
              this.key++
              })
            }})
        } else if (action.action == 'edit') {
          this.locationModel = action.location
          this.locationModelKey++
          this.$bvModal.show('modal-edit-location')
        }
      },
      merge() {
        this.$store.dispatch('mergeLocations', {
          mainLocationId: this.mainEntityId,
          locationIds: this.selected.filter(location => location.id != this.mainEntityId).map(location => location.id)
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Locations merged`
          })
          this.selected = []
          this.mainEntityId = null
          this.key++
        })
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      }
    }
  }
</script>
